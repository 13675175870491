// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

import { SlideInternalDiv, SlideInternalP } from "./TestimonialStyles";

export const TestimonialSwiper = () => {
  const slideStyles = {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.2rem",
    lineHeight: "2rem",
    fontWeight: "400",
    color: "white",
    fontStyle: "italic",
    opacity: "inherit",
  };
  return (
    <Swiper
      modules={[Autoplay, EffectFade]}
      effect="fade"
      spaceBetween={50}
      slidesPerView={1}
      style={{ width: "100%", height: "100%", zIndex: 1 }}
      autoplay={true}
      fadeEffect={{ crossFade: true }}
    >
      <SwiperSlide style={slideStyles}>
        <SlideInternalDiv>
          "Was very impressed by the level of finish and sense of space inside.
          It is furnished and equipped to a level beyond a holiday let, and is
          in immaculate condition. Very suitable for families"
          <SlideInternalP>Sharon Cooney</SlideInternalP>
        </SlideInternalDiv>
      </SwiperSlide>
      <SwiperSlide style={slideStyles}>
        <SlideInternalDiv>
          "There is a view of the lake from upstairs, and a bluebell wood at the
          back of the house, perfect for wildlife observation! I wouldn't
          hesitate to recommend this property."
          <SlideInternalP>Sharon Cooney</SlideInternalP>
        </SlideInternalDiv>
      </SwiperSlide>
      <SwiperSlide style={slideStyles}>
        <SlideInternalDiv>
          "Fantastic place, beautiful with loads of room. It was such a relaxing
          time"
          <SlideInternalP>Owen Mason</SlideInternalP>
        </SlideInternalDiv>
      </SwiperSlide>
      <SwiperSlide style={slideStyles}>
        <SlideInternalDiv>
          "I really enjoyed my time building the Website for this Lodge. It
          really looks beautiful"
          <SlideInternalP>Joshua Dancey</SlideInternalP>
        </SlideInternalDiv>
      </SwiperSlide>
      <SwiperSlide style={slideStyles}>
        <SlideInternalDiv>
          "It was truly a cosy and comfy place to hang out over for a family
          reunion
          <SlideInternalP>Joshua Dancey</SlideInternalP>
        </SlideInternalDiv>
      </SwiperSlide>
    </Swiper>
  );
};
