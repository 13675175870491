import { flexbox } from "@mui/system";
import styled from "styled-components";
import { colorScheme as colors } from "../../css variables/colorsScheme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 40px;
  background-color: ${colors.dark};
  color: ${colors.extraLight};
  flex-wrap: wrap;
  position: relative;
`;

export const SocialIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const SocialIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-right: 5px;
  cursor: pointer;
  &:hover {
    color: white;
  }
`;

export const CopyrightDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  color: ${colors.extraLight};
  cursor: pointer;
  &:hover {
    color: white;
  }
  @media (max-width: 480px) {
    &:nth-child(2) {
      order: 1;
    }
  }
`;
