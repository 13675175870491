import { Copyright, Facebook, Instagram } from "@mui/icons-material";
import React from "react";
import {
  Container,
  SocialIconDiv,
  SocialIcon,
  CopyrightDiv,
} from "./FooterStyles";

function Footer() {
  return (
    <Container>
      <SocialIconDiv>
        <SocialIcon>
          <Facebook />
        </SocialIcon>
        <SocialIcon>
          <Instagram />
        </SocialIcon>
      </SocialIconDiv>
      <CopyrightDiv>
        <Copyright style={{ marginRight: "5px" }} /> joshuadanceywebdev.ie
      </CopyrightDiv>
      <CopyrightDiv>Email: leonarddancey@hotmail.com</CopyrightDiv>
    </Container>
  );
}

export default Footer;
